import React from "react";
import { PageWrapper } from "~components/Core";
// import HeroSection from '~sections/project/Hero'
import HeroSection from "~sections/marketing/Hero"

import FeatureSection from '~sections/project/Features'
import ContentOne from '~sections/project/ContentOne'
import ContentTwo from '~sections/project/ContentTwo'
import IntegrationSection from '~sections/project/Integration'

import AboutSection from '~sections/startup/About'


import FooterFive from '~sections/agency/FooterFive'


import HeaderButton from '~sections/project/Header'

import ServicesSection from '~sections/agency/Services'
import CtaSection from '~sections/it/Cta1'

import TestimonialSection from '~sections/agency/Testimonial'

import Whatsapp from "~sections/whatsapp";




const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid: false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnTwoText="Get Started For Free"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Project() {
  return (
    <PageWrapper innerPage={true}>

      {/* <Whatsapp /> */}

      <ContentOne />

      <AboutSection />

      {/* <HeroSection /> */}


      {/* <ContentTwo /> */}

      <ServicesSection />


      {/* <TestimonialSection /> */}

      <CtaSection />

      <FooterFive />

    </PageWrapper>
  )
}
