import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { StaticImage as Img } from 'gatsby-plugin-image'
import SectionTitle from './Components/SectionTitle'
import About from './style'

import image from '../../../assets/image/project-management/3.gif'


export default function AboutusSection() {
  return (
    <About>






      <Container>
        <Row className="align-items-center justify-content-center g-0">
          <Col xs="12" className="col-xxl-6 col-lg-5 col-md-10">
            {/* <a href="https://api.whatsapp.com/send?phone=94716024489" class="float-wa" target="_blank">
            <i class="fa fa-whatsapp my-float-wa"></i>
          </a> */}





























            <About.Image>
              <img className="w-100" src={image} alt="content" layout="fullWidth" placeholder="blurred" />
              {/* <Img className="w-100" src="../../../assets/image/home-startup/about-us-img.png" alt="content" layout="fullWidth" placeholder="blurred" /> */}
            </About.Image>
          </Col>
          <Col className="col-xxl-6 col-lg-7 col-md-10">
            <About.Box>
              {/* Section Title */}
              <About.Content>
                <SectionTitle
                  subTitle="About us"
                  title="Grow Your Business with Us! "
                  text="We Axcer, experts in a vast range of technologies focusing on cutting edge technologies and the
                   platforms which can contribute to accelerating your business with modern solutions. Reach us with your idea,
                    we will design the best solution which accelerate your business."
                  subTitleProps={{ mb: "10px", className: "subline", fontColor: "#5034fc" }}
                  titleProps={{ mb: "10px", as: "h2" }}
                  mb="69px" />
              </About.Content>
              {/*/ .Section Title */}
            </About.Box>
          </Col>
        </Row>
      </Container>
    </About >


  )
}