import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Images } from '~data'
import data from '~data/data'
import { Link } from 'gatsby'

import Cta from './style'
import { openPopupWidget } from "react-calendly"

// var url = "https://calendly.com/axcer/30min";


// var url = data.calendlyUrl;

// const pop = () => openPopupWidget({ url });


export default function CtaSection() {
    return (
        <Cta backgroundImage={Images.itServices.promoBg}>
            <Container>
                <Row className="justify-content-center">
                    <Col xs="12" className="col-xl-7 text-center">
                        <Cta.Title as="h2" fontColor="#fff" mb="10px">Book free consultation</Cta.Title>
                        <Cta.Text fontColor="#fff">Axcer’s main goal is to accelerate your   <br className="d-none d-xs-block" /> business with our solutions. </Cta.Text>

                        <Link to="/book">  <Cta.Button sizeX="180px" sizeY="56px" mt="20px">
                            Get Started
                        </Cta.Button>
                        </Link>

                    </Col>
                </Row>
            </Container>
        </Cta>
    )
}

// style={{backgroundImage: `url(${})`}}